import React, { useEffect } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import "../../styles/inline/themes.scss"
import styles from "./style.module.scss"

const LinkWrapper = ( props ) => (
	<>
		{ props.offsite ?
			<a
				href={ props.link }
				className={ props.className }
				bg={ props.bg }>
				{props.children}
			</a>
		:
			<Link
				to={ props.link }
				className={ props.className }
				bg={ props.bg }>
				{props.children}
			</Link>
		}
	</>
)

const LogoBlock = ( props ) => {

	return (
		<LinkWrapper 
			offsite={ props.offsite }
			link={ props.link }
			className={ styles.block }
			bg={ props.hex }>			
			<div className={ styles.overlay }>
				<div className={ styles.content }>
					<img className={ styles.image } src={ props.logo } />
					<p className={ styles.text }>{ props.brand }</p>
				</div>
			</div>
			{ props.background && 
				<Img className={ styles.background } 
					 fluid={ props.background } 
					 imgStyle={{objectPosition: props.position }}/>
			}
		</LinkWrapper>
	)
}

export default LogoBlock
